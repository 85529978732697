.LockIcon{
    color: white;
}

.circle-purple{
    background-color: blueviolet;
}

.box{
    padding-top: 1%;
    padding-bottom: 1%;
}

.textField{
    padding-top: 1%;
    padding-bottom: 1%;
}

.test{
    font-size: small;
}

.hidden{
    display: none;
}
