.card-grid {
  padding: 10px;
  box-sizing: border-box;
}

.card {
  background-color: #fff;
  width: 100%;
  padding: 30px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 3px 6px #00000038;
}

.card > div:first-child {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.card > div:nth-child(2) {
  display: flex;
  gap: 10px;
  align-items: center;
}
.card div:nth-child(2) div {
  display: flex;
  align-items: center;
  gap: 2.5px;
}
.card div:nth-child(2) div p {
  font: 600 15px Pretendard;
}

.card .last-update-text {
  font: 600 14px Pretendard;
  color: rgb(102, 112, 133);
}

.card .title {
  font: 600 18px Pretendard;
  margin-bottom: 10px;
}

.card .value {
  font: 800 30px Pretendard;
}

.icon {
  background-color: rgb(21, 183, 159);
  border-radius: 50%;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 3px 3px #00000038;
}
.icon svg {
  color: #fff;
  font-size: 30px;
}
