.container {
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  color: #333;
  overflow-x: hidden;
}

.back_btn {
  display: flex;
  gap: 5px;
  align-items: center;
  color: #333 !important;
}
.back_btn svg {
  font-size: 32px;
}
.back_btn p {
  font: 600 20px Pretendard;
}
.back_btn p:hover {
  text-decoration: underline;
}

.customer_info {
  display: flex;
  justify-content: space-between;
}
.customer_info > :first-child {
  display: flex;
  gap: 10px;
  align-items: center;
}
.customer_info > :first-child .avatar {
  width: 100px;
  height: 100px;
}
.customer_info .name {
  font: 700 32px Pretendard;
}
.customer_info .email {
  font: 500 21px Pretendard;
  color: #666;
  width: 100%;
}

div:has(> .card) {
  padding: 10px;
}
.card {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 3px 3px #00000038;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
.card > div {
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;
}
.card > div:last-child {
  border-bottom: none;
}
.card#details > div:nth-child(even) {
  background-color: #f5f5f5;
}
.card > .header {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #333;
}
.card > .header p {
  font: 700 22px Pretendard;
}

.card#details > div:not(.header) {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.card#details .key {
  font: 500 18px Pretendard;
  color: #777;
}
.card#details .value {
  font: 600 20px Pretendard;
  color: #333;
}
.card#details .period {
  font: 400 16px Pretendard;
  color: #999;
  transform: translate(5px, -5px);
}

.icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px #aaa;
}
.icon svg {
  font-size: 29px;
}

div:has(> .table) {
  padding: 20px;
  flex: 1;
}
.table#history {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 3px 6px #00000038;
  width: 100%;
  height: 100%;
}
.table#history td {
  box-sizing: border-box;
}
.table#history thead tr {
  background-color: #428bca;
}
.table#history thead tr th {
  font: 700 18px Pretendard;
  color: #fff;
  font-size: clamp(12.77px, 4.05vw, 18px);
}
tr:has(.action_row) {
  height: 40px;
  border-top: 1px solid #e0e0e0;
}
.table#history .action_row {
  border-bottom: 1px solid #00000000;
  padding: 0;
}
.action_row div {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-end;
}
.action_row button {
  padding: 5px;
}
.action_row button svg {
  font-size: 24px;
}
.action_row p {
  font: 500 16px Pretendard;
}
.table#history tbody tr:nth-child(even) {
  background-color: #fff;
}
.table#history tbody tr:nth-child(odd) {
  background-color: #f5f5f5;
}
tr:has(.empty_cell) {
  background-color: #fff !important;
}
tbody .empty_cell {
  border-bottom: none;
}
tbody .empty_cell > div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
tbody .empty_cell > div > p {
  font: 600 20px Pretendard !important;
  color: #777;
}

div:has(> .subscription) {
  height: 100%;
  padding: 20px;
}
.subscription {
  height: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  box-shadow: 0 3px 6px #00000038;
  overflow: hidden !important;
}
.subscription thead {
  background-color: #428bca;
}
.subscription th {
  font: 700 18px Pretendard;
  color: #fff;
  font-size: clamp(12.06px, 3.828vw, 17px);
}
.subscription tbody tr td {
  font: 500 16px Pretendard;
  font-size: clamp(10.64px, 3.37vw, 15px);
}
.subscription tbody tr td p {
  font: 500 16px Pretendard;
  word-break: keep-all;
  font-size: clamp(10.64px, 3.37vw, 15px);
}
.subscription tbody tr:nth-child(odd) {
  background-color: #f5f5f5;
}
.subscription tr:has(.action_row) td {
  height: 40px !important;
  padding: 2.5px 0 !important;
  border-bottom: 0;
}
.empty_row {
  background-color: #fff !important;
}
.empty_row td {
  border-bottom: 0;
}

@media screen and (max-width: 768px) {
  .subscription thead {
    display: none;
  }

  .subscription tbody tr {
    display: flex;
    flex-wrap: wrap;
  }
  .subscription tbody tr td:before {
    font-weight: 700;
    content: attr(data-label) " : ";
  }
  .subscription tbody tr td.action_row:before,
  .subscription tbody tr td[data-label="영수증"]:before,
  .subscription tbody tr td.empty_cell:before {
    content: "";
  }
  .subscription tbody tr td {
    flex-shrink: 0;
    border-bottom: 0px solid #e0e0e0;
    padding: 10px 5px;
    box-sizing: border-box;
    font-size: clamp(12.77px, 4.05vw, 18px);
  }
}
