.container {
  padding: 0 10px;
  box-sizing: border-box;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.header h1 {
  font-size: clamp(18.45px, 5.86vw, 26px);
  font-weight: bold;
}

.card_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 20px;
}
.card {
  background-color: #ffffff;
  box-shadow: 0px 3px 3px #00000038;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  border-bottom: none;
  overflow: hidden;
  color: #333;
}
.card > div {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}
.card > div > :first-child {
  font: 700 16px Pretendard;
  margin: auto;
  word-break: keep-all;
}
.card > div > :last-child {
  font: 600 16px Pretendard;
  margin: auto;
  word-break: keep-all;
}
.card > :nth-child(2n + 1) {
  background-color: #eee;
}
.card > :nth-child(2n) {
  background-color: #fff;
}
.card > :first-child {
  font: 800 20px Pretendard;
}
.card > :last-child {
  display: flex;
  border-bottom: none;
  padding: 0;
}
.card > :last-child button {
  width: 100%;
  border-radius: 0;
  box-shadow: none;
  font: 700 16px Pretendard;
  padding: 10px;
}
.card > :last-child button:first-child {
  background-color: #c33c3c;
  border: #ffdc3c;
}

.button {
  background-color: #ffdc3c;
  border: #ffdc3c;
  box-shadow: 5px 5px 5px #dee2e6;
  cursor: pointer;
  font: 600 16px Pretendard;
  padding: clamp(10.64px, 3.38vw, 15px) clamp(7.09px, 2.25vw, 10px);
  font-size: clamp(11.35px, 3.6vw, 16px);
}

.modal {
  width: 90% !important;
  padding: 20px 10px !important;
}
