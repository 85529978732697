.body {
  padding: 10px;
  padding-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.body > .title {
  font-size: clamp(22px, 7.207vw, 32px);
  margin: 0;
}
.body > .search_box {
  display: flex;
  gap: 10px;
  align-items: center;
}
.body .user_list {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.loading_box {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide_up_btn {
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  background-color: #c33c3c !important;
  border-radius: 50%;
  box-shadow: 0 3px 3px #00000038;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  color: #fff !important;
}
.slide_up_btn:hover {
  background-color: #c33c3c;
}
.slide_up_btn svg {
  font-size: 32px;
}

.detail_btn {
  font-weight: 600;
  background-color: skyblue !important;
}

.tabs_container {
  border-bottom: 1px solid #c0c0c0;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 3px #00000038;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 3px #00000038;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  border-bottom: none;
  color: #333;
}
.card > :nth-of-type(2n) {
  background-color: #fff;
}
.card > :nth-of-type(2n + 1) {
  background-color: #f5f5f5;
}
.card > .card_row:nth-of-type(n) {
  padding: 5px 10px;
  border-bottom: 1px solid #e0e0e0;
}
.card > .card_row:nth-of-type(n) .type {
  font-weight: 700;
  font-size: 18px;
}
.card > .card_row:nth-of-type(n) .value {
  font-weight: 600;
}
.card > .card_row:first-child {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card > :first-child > p {
  font: 800 20px Pretendard;
}
.card > :last-child {
  padding: 10px;
  border-bottom: none;
  font: 600 18px Pretendard;
}
