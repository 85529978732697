.body {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
}
.title {
  font: 700 32px Pretendard !important;
  font-size: clamp(22px, 7.207vw, 32px);
  padding: 10px;
}

div:has(> .table_container) {
  padding: 10px;
}
.table_container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.table_container thead {
  background-color: skyblue;
}
.table_container thead th {
  color: #fff;
  font: 700 20px Pretendard !important;
  word-break: keep-all;
}
#group_list {
  position: relative;
  height: 500px;
}
#group_list thead tr:first-child th {
  position: sticky;
  top: 0;
  background-color: skyblue;
}
tbody td {
  font: 500 16px Pretendard !important;
  word-break: keep-all;
}
tbody tr:nth-child(even) {
  background-color: #f0f0f0;
}

.hidden {
  display: none !important;
}

div:has(> .back_btn) {
  padding: 10px;
}
.back_btn {
  color: black !important;
  display: flex;
  gap: 5px;
  align-items: center;
}
.back_btn > p {
  font: 600 20px Pretendard !important;
  font-size: clamp(20px, 6.207vw, 32px);
}
.back_btn > p:hover {
  text-decoration: underline;
}

.grid > div {
  padding: 10px;
  box-sizing: border-box;
}
.info > div {
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 3px 3px #00000038;
  overflow: hidden;
  border: 1px solid #e0e0e0;
}
.info > div > div {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}
.info > div > div:nth-child(even) {
  background-color: #f0f0f0;
}
.info > div > div:last-child {
  border-bottom: none;
}
.info .header {
  display: flex;
  gap: 10px;
  align-items: center;
}
.header .icon {
  border: 1px solid #ccc;
  box-shadow: 0 0 10px #aaa;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
.header .icon svg {
  font-size: 28px;
}
.header p {
  font: 700 22px Pretendard !important;
}
.info .content:before {
  content: attr(data-label) "";
  font-weight: 600;
  font-size: 18px;
  color: #777;
}
.info .content > p {
  padding-left: 10px;
  padding-top: 5px;
  font: 700 20px Pretendard !important;
  color: #333;
}

.payment {
  background-color: #fff;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 3px 3px #00000038;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.payment > div {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}
.payment > div:last-child {
  border-bottom: none;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.payment .header {
  display: flex;
  gap: 10px;
  align-items: center;
}
.file_uploader {
  width: 100% !important;
  max-width: none !important;
  min-width: 0 !important;
  box-sizing: border-box !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
}
.file_uploader span {
  font: 700 20px Pretendard;
}

@media (max-width: 768px) {
  .table_container > table {
    border-collapse: separate;
    border-spacing: 0px 10px;
  }
  .table_container tbody {
    display: flex;
    flex-direction: column;
  }
  .table_container tr {
    display: flex;
    flex-direction: column;
    margin: 10px;
    box-sizing: border-box;
    width: calc(100% - 20px);
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    overflow: hidden;
  }
  .table_container tr > td {
    /* width: 100%; */
    border: none;
    background-color: #f5f5f5;
  }
  tbody tr > td {
    font: 500 13px Pretendard !important;
    font-size: clamp(13px, 4.127vw, 24px) !important;
  }
  tbody td:before {
    content: attr(data-label) " : ";
    font-weight: bold;
  }
  #group_list tbody td:before {
    content: attr(data-label) " : " !important;
    font-weight: bold;
  }
  tbody tr > td:last-child:before {
    content: "";
  }
  tbody tr > td:last-child {
    padding: 0;
  }
  #group_list tbody td:last-child {
    padding: 10px 15px !important;
  }
  td > button {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: skyblue !important;
    border-radius: 10px;
    cursor: pointer;
    color: #fff !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  td > button > p {
    font: 700 20px Pretendard !important;
    margin-left: 5px !important;
  }
}
