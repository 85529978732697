.titleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  /* width: 100px; */
  height: 50px;
  background-color: #ffdc3c;
  border: #ffdc3c;
  box-shadow: 5px 5px 5px #dee2e6;
  cursor: pointer;
  padding: clamp(7px, 2.25vw, 10px);
  font-size: clamp(10px, 3.15vw, 14px);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  background-color: #ffb400;
  transition: 0.7s;
}

#refresh {
  border: none;
  background-color: #fff;
  width: clamp(35px, 11.26vw, 50px);
  font-size: clamp(17px, 5.41vw, 24px);
}

#refresh:hover {
  background-color: #eee;
}

.aws_table_row td,
.aws_table_row tr,
.aws_table_row th {
  text-align: center;
}
.aws_table_row td div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
